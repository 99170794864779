@import '../../styles/lincoln/main.scss';

.brand-lincoln {
    .redeem-rebates-whole {
        background-color: $eyebrow-grey;

        .redeem-rebates-banner {
            width: 100%;
            align-items: center;
            justify-content: center;
            @media print {
                display: none;
            }
        }
        .redeem-rebates-method {
            text-transform: uppercase;
            margin: 2rem 0;
            text-align: center;
            font-family: $proxima-nova-regular;
            font-size: 32px;
            font-weight: 300;
            letter-spacing: 2px;
            line-height: 42px;
            color: $brand-secondary;
            @media print {
                display: none;
            }
        }

        .redeem-rebates-us {
            margin: 2rem 0;
            color: $dropdown-text;
            color: $brand-secondary;
            font-family: $proxima-nova-regular;
            font-size: 18px;
            letter-spacing: 0;
            line-height: 26px;
            text-align: center;
            @media print {
                display: none;
            }
        }

        .buttons-secondary-building-blo {
            text-align: center;
            margin: 0 0 1rem;
            .secondary-button {
                .secondary-button-link {
                    color: $brand-secondary;
                    font-family: $proxima-nova-semi-bold;
                    font-weight: bold;
                    font-size: 16px;
                    letter-spacing: 0;
                    line-height: 20px;

                    .secondary-button-text {
                        border: none;
                        padding-bottom: 0;
                        &:hover {
                            border-width: 1px;
                            padding-bottom: 2px;
                            border-bottom: 2px solid #f26146;
                        }
                    }

                    .fds-chevron-wrap {
                        display: block;
                        .fds-chevron {
                            .fds-dot {
                                display: none;
                            }
                            .fds-arrow {
                                &.filled {
                                    margin-left: 0;
                                    color: inherit;
                                }
                            }
                        }
                    }
                }
            }
            @media print {
                display: none;
            }
        }

        .disclosures-button-section {
            @media print {
                display: none;
            }
        }
    }
    .whole-rebates {
        .shared-view {
            .search-bar {
                margin-top: 0;
            }
        }
    }
}
