@import '../../styles/main.scss';

.sam-reimagine-category-view {
    background-color: $brand-white;

    .category-view-masthead-container {
        .category-view-masthead.collision-assistance,
        .category-view-masthead.auto-repairs,
        .category-view-masthead.certified-centers,
        .category-view-masthead.original-parts,
        .category-view-masthead.collision-insurance {
            height: 350px;
            .category-view-masthead__wrapper {
                padding: 255px 0 0 55px;
                margin: 0;
                text-align: left;
                @include mobile {
                    padding: 290px 0 0 25px;
                }
                .category-view-masthead__category-headline {
                    font-size: 48px;
                    line-height: 56px;
                    font-family: $f1-font-regular;
                    text-transform: none;
                    @include mobile {
                        font-size: 32px;
                        line-height: 40px;
                    }
                }
            }
        }
    }

    .info-quiet-cards-collision-assistance,
    .info-quiet-cards-auto-repairs,
    .info-quiet-cards-certified-centers,
    .info-quiet-cards-original-parts,
    .info-quiet-cards-collision-insurance {
        .category-quiet-cards {
            margin: 48px 56px 0 56px !important;
            padding: 0 !important;
            @include mobile {
                margin: 48px 15px !important;
            }
            .category-quiet-card__content-image {
                margin-bottom: 24px !important;
                .category-quiet-card__content-wrap {
                    width: 100%;
                    padding-right: 0 !important;
                    @include mobile {
                        width: 95%;
                    }
                    .category-quiet-card__content-wrap__title {
                        font-family: $f1-font-regular;
                        font-weight: 400;
                        font-size: 32px;
                        line-height: 40px;
                        text-transform: none;
                        color: $fm-text-grey;
                    }
                    .category-quiet-card__content-wrap__body {
                        font-family: $f1-font-regular;
                        font-weight: 400;
                        font-size: 16px;
                        line-height: 24px;
                        color: $fm-text-grey;
                        @include mobile {
                            margin-bottom: 0;
                        }
                    }
                }
                .category-quiet-card__image-wrap {
                    display: none;
                }
            }
        }
        .fds-chevron-wrap {
            display: none;
        }
    }

    .quiet-cards-one-collision-assistance,
    .quiet-cards-one-collision-insurance {
        .category-quiet-cards {
            margin: 50px 0 0 40px;
            @include mobile {
                margin: 60px 0 0 0;
            }
            .category-quiet-card__content-wrap {
                .category-quiet-card__content-wrap__cta {
                    a:nth-child(2) {
                        color: $fm-breadcrumb-blue;
                    }
                    a:nth-child(2)::before {
                        background-color: $fds-color-white;
                        border: 1px solid $fm-breadcrumb-blue;
                    }
                }
            }
        }
    }

    .quiet-cards-collision-assistance,
    .quiet-cards-auto-repairs,
    .quiet-cards-certified-centers,
    .quiet-cards-original-parts,
    .quiet-cards-collision-insurance {
        .category-quiet-cards {
            margin: 0 !important;
            padding: 0 50px !important;
            .category-quiet-card__content-wrap {
                @include desktop {
                    padding: 0 0 0 20px !important;
                    align-content: flex-start !important;
                }
                .category-quiet-card__content-wrap__cta {
                    .category-quiet-card__content-wrap__cta__button {
                        color: $fm-breadcrumb-blue;
                    }
                    .category-quiet-card__content-wrap__cta__button::before {
                        border: 1px solid $fm-breadcrumb-blue;
                    }
                }
            }
            .category-quiet-card__image-wrap__mobile-image,
            .category-quiet-card__image-wrap__desktop-image {
                background-size: contain;
                background-repeat: no-repeat;
            }

            .category-quiet-card__image-wrap__desktop-image {
                background-position: top;
            }
        }
    }

    .quiet-cards-collision-assistance,
    .quiet-cards-auto-repairs,
    .quiet-cards-certified-centers,
    .quiet-cards-original-parts,
    .quiet-cards-collision-insurance,
    .quiet-cards-one-collision-assistance,
    .quiet-cards-one-auto-repairs,
    .quiet-cards-one-certified-centers,
    .quiet-cards-one-original-parts,
    .quiet-cards-one-collision-insurance {
        .category-quiet-cards {
            .category-quiet-card__content-wrap {
                align-content: flex-start;
                .category-quiet-card__content-wrap__title {
                    font-family: $f1-font-regular;
                    font-size: 32px;
                    line-height: 40px;
                    text-transform: none;
                    color: $fm-text-grey;
                    font-weight: normal !important;
                }
                .category-quiet-card__content-wrap__body {
                    font-size: 16px;
                    line-height: 24px;
                    color: $fm-text-grey;
                }
            }
            .category-quiet-card__image-wrap__mobile-image,
            .category-quiet-card__image-wrap__desktop-image {
                background-size: contain;
                background-repeat: no-repeat;
            }
        }
        .fds-chevron-wrap {
            display: none;
        }
    }

    .ca-article-carousel {
        .ca-article-carousel__title {
            font-size: 40px;
            line-height: 48px;
            color: $fm-text-grey;
            font-family: $f1-font-regular;
        }
        .ca-article-carousel__subtitle {
            margin: 16px 16px 0 0;
            font-size: 16px;
            line-height: 24px;
            color: $fm-text-grey;
            font-family: $f1-font-regular;
        }
        .fm-carousel__container {
            margin: 0;
            .fm-card__container .fm-card__content--container {
                display: none;
            }
        }
    }

    .video-quiet-card-main {
        flex-direction: row-reverse;
        @include mobile {
            flex-direction: column-reverse;
        }
        .video-quiet-card-content {
            @include mobile {
                margin: 15px;
                width: 100% !important;
                padding: 0;
            }
            margin-left: 60px;
            width: 650px;
            padding-top: 25px;
            margin-right: 46px;
            .video-quiet-card-title {
                font-family: $f1-font-regular;
                font-weight: 400;
                font-size: 32px;
                line-height: 40px;
                text-transform: none;
                color: $fm-text-grey;
                @include mobile {
                    text-align: left;
                }
            }
            .video-quiet-card-description {
                font-weight: 400;
                font-size: 16px;
                line-height: 24px;
                color: $fm-text-grey;
                margin-bottom: 35px;
                @include mobile {
                    text-align: left;
                    margin: 0 15px 35px 0;
                }
            }
            .video-quiet-card-disclaimer {
                font-family: $f1-font-medium;
                font-weight: 500;
                font-size: 12px;
                line-height: 16px;
                color: $darkgrey;
                margin-bottom: 30px;
            }
            .cta-optional__primary-button {
                color: $fm-breadcrumb-blue;
            }
            .cta-optional__primary-button {
                &:hover {
                    &::before {
                        background-color: transparent !important;
                    }
                }
            }

            .cta-optional__primary-button::before {
                border: 1px solid $fm-breadcrumb-blue;
                background-color: transparent;
            }
            .fds-chevron-wrap {
                display: none;
            }
        }
        .image-or-video {
            margin-top: 0;
            margin-left: 65px;
            width: 884px;
            height: 497px;
            border-radius: 8px;
            @include mobile {
                width: 100% !important;
                height: 100%;
                margin-left: 0;
            }
        }
    }
    .disclaimer-list-item {
        display: none;
    }

    .quiet-cards-auto-repairs,
    .quiet-cards-certified-centers,
    .quiet-cards-original-parts,
    .quiet-cards-collision-insurance {
        .category-quiet-cards {
            .category-quiet-card__content-image {
                @include desktop {
                    height: 492px;
                    margin-bottom: 68px;
                    gap: 50px;
                }
                .category-quiet-card {
                    &__image-wrap {
                        &__desktop-image {
                            border-radius: 8px;
                            height: 100%;
                        }
                    }
                    &__content-wrap__title {
                        text-transform: none;
                        color: $fm-text-grey;
                        font-weight: bold;
                    }
                    &__content-wrap {
                        @include desktop {
                            padding: 0px;
                            align-content: center;
                        }
                    }

                    &__content-wrap__cta {
                        &__button {
                            color: $fm-breadcrumb-blue;

                            .fds-chevron-wrap {
                                display: none;
                            }
                        }
                        &__button::before {
                            border: 1px solid $fm-breadcrumb-blue;
                        }
                    }
                }
            }

            .category-quiet-card__image-content {
                @include desktop {
                    height: 492px;
                    margin-bottom: 68px;
                    gap: 50px;
                }
                .category-quiet-card {
                    &__image-wrap {
                        &__desktop-image {
                            padding: 0px;
                            border-radius: 8px;
                            height: 100%;
                        }
                    }
                    &__content-wrap {
                        @include desktop {
                            align-content: center;
                        }
                    }
                    &__content-wrap__title {
                        text-transform: none;
                        color: $fm-text-grey;
                        font-weight: bold;
                    }
                    &__content-wrap__cta {
                        &__button {
                            color: $fm-breadcrumb-blue;

                            .fds-chevron-wrap {
                                display: none;
                            }
                        }
                        &__button::before {
                            border: 1px solid $fm-breadcrumb-blue;
                        }
                    }
                    &__image-content__content-wrap {
                        @include desktop {
                            padding: 0px;
                        }
                    }
                }
            }
        }
    }

    .ca-article-carousel {
        .fm-carousel__container {
            @include desktop {
                margin: 30px 0 0 0;
                padding-left: 86px !important;
            }
        }
        .fm-carousel__wrapper {
            @include desktop {
                padding: 0;
            }
        }
    }
    .disclaimer-list-container {
        margin: 50px auto;
        @include mobile {
            margin: 0;
        }
    }

    .category-quiet-cards-trio__divider {
        display: none;
    }

    .preimagine-escape-hatch {
        padding: 0 56px 60px 56px !important;
        margin: 0 !important;
    }

    .category-quiet-cards-trio {
        margin-bottom: 0 !important;
        .category-quiet-cards-trio__title {
            font-family: $f1-font-regular;
            font-size: 54px;
            line-height: 64px;
            color: $fm-text-grey;
        }
        .quiet-card .quiet-card__title {
            font-family: $f1-font-regular;
            font-size: 32px;
            line-height: 40px;
            color: $fm-text-grey;
            text-transform: none;
            text-align: left;
        }
        .quiet-card__button-wrapper {
            display: flex;
            justify-content: left;
            margin-left: 20px;
        }
        .quiet-card .quiet-card__image {
            border-radius: 8px;
        }
        .quiet-card {
            margin-bottom: 25px;
        }
        .fds-chevron-wrap {
            display: none;
        }
    }

    .component-box-5050 {
        margin: 0 0 50px 0 !important;
        .component-box-5050-body {
            .component-box-5050-subtitle {
                font-family: $f1-font-regular;
                font-size: 40px;
                line-height: 48px;
                color: $fm-text-grey;
                text-transform: none;
            }
            .component-box-5050-description {
                font-family: $f1-font-regular;
                font-size: 16px;
                line-height: 24px;
                color: $fm-text-grey;
            }
            .component-box-5050-link {
                color: $fm-breadcrumb-blue;
                .fds-chevron-wrap {
                    display: none;
                }
            }
            .component-box-5050-link::before {
                border: 1px solid $fm-breadcrumb-blue;
            }
        }
    }
}
