.brand-lincoln {
    .shared-view.video-details {
        margin-top: 0px;
    }
    .htv-video-page {
        background-color: $fds-color-lt-gray;
        @media screen and (min-width: $ipad-landscape-width) and (max-width: $max-tab-bar-width) {
            margin-top: 28px;
        }
        @media screen and (min-width: $max-tab-bar-width) {
            margin-top: 10px;
        }
        .breadcrumbs {
            width: 90%;
            .breadcrumbs-content {
                display: block;
                @include mobile {
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    color: $brand-secondary;
                }
            }
        }
        .breadcrumbAccordion {
            .fds-chevron-wrap {
                display: none;
                @include mobile {
                    display: block;
                    line-height: 70px;
                }
            }
            @include mobile {
                display: flex;
                &.expanded {
                    .breadcrumbs {
                        .breadcrumbs-content {
                            overflow: visible;
                            text-overflow: none;
                            white-space: normal;
                        }
                    }
                }
            }
        }
        .htv-content-wrapper {
            width: 100%;

            .video-page-title {
                h1 {
                    font-size: 1.2rem;
                    text-transform: capitalize;
                    font-weight: 900;
                    letter-spacing: 0rem;
                    color: $brand-secondary;
                    font-family: $lincoln-millerb-semibold;
                    margin: 0.5rem auto 0 auto;
                    width: 90%;

                    @include tablet {
                        font-size: 1.7rem;
                    }

                    @include xl-desktop {
                        font-size: 2.5rem;
                        width: 80%;
                        margin: 10px 10% 0px 10%;
                    }
                }
            }

            .how-to-video-popular-videos {
                margin-bottom: 64px;
            }

            .empty-video {
                text-align: center;
                font-family: $f1-font-regular;
                font-size: 1rem;
                padding: 150px 0;
            }

            .no-video {
                padding-bottom: 125px;
            }
        }
    }
}
