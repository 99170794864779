.brand-ford {
    .redeem-rebates-whole {
        background-color: $eyebrow-grey;

        .redeem-rebates-banner {
            width: 100%;
            align-items: center;
            justify-content: center;
            @media print {
                display: none;
            }
        }
        .redeem-rebates-method {
            text-transform: uppercase;
            margin: 2rem 0;
            text-align: center;
            font-family: $f1-font-light;
            font-size: 34px;
            line-height: 44px;
            color: $dropdown-text;
            @media print {
                display: none;
            }
        }

        .redeem-rebates-us {
            margin: 2rem 0;
            text-align: center;
            font-family: $f1-font-regular;
            color: $dropdown-text;
            font-size: 16px;
            line-height: 26px;
            @media print {
                display: none;
            }
        }

        .buttons-secondary-building-blo {
            display: flex;
            justify-content: center;
            margin: 0 0 1rem;
            @media print {
                display: none;
            }
        }

        .disclosures-button-section {
            @media print {
                display: none;
            }
        }
    }
    .whole-rebates {
        .shared-view {
            .search-bar {
                margin-top: 0;
            }
        }
    }
}
