.brand-lincoln {
    .category-view {
        background-color: $fds-color-lt-gray;
    }
    .sub-category {
        border: 1px solid $brand-primary;
        font-family: $proxima-nova-light;
        text-align: center;
        letter-spacing: 3px;
        line-height: 70px;
    }
}
