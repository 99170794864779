.brand-lincoln {
    #bbt {
        margin-top: 40px;
        .bbt-title {
            color: $brand-secondary;
            font-family: $lincoln-millerb-black;
            font-size: 34px;
            font-weight: 300;
            letter-spacing: 4px;
            line-height: 44px;
            text-align: center;
            margin-bottom: 0px;
            text-transform: uppercase;
            .section-heading-link {
                color: $brand-secondary;
            }
        }
        .bbt-subtitle {
            color: $brand-secondary;
            font-family: $proxima-nova-regular;
            font-size: 16px;
            letter-spacing: 1px;
            line-height: 26px;
            text-align: center;
            margin-top: 15px;
            margin-bottom: 0px;
        }
        .categories {
            margin-left: auto;
            margin-right: auto;
            margin-bottom: 60px;
            width: fit-content;
            .categories-list {
                justify-content: center;
                list-style: none;
                display: inline-grid;
                padding-top: 90px;
                width: 25%;
                .category-link {
                    color: $brand-secondary;
                    font-family: $proxima-nova-regular;
                    font-size: 18px;
                    letter-spacing: 1px;
                    line-height: 28px;
                    text-align: center;
                    text-decoration: none;
                    .category-name {
                        position: relative;
                        top: 5px;
                    }

                    .category-icon {
                        height: 48px;
                        width: 48px;
                    }
                }
            }
        }
    }

    @include mobile {
        #bbt {
            margin: 60px 27px;
            .categories {
                .categories-list {
                    padding-top: 50px;
                    width: auto;
                    display: block;
                    .category-link {
                        display: flex;
                        width: 100%;
                        .category-icon {
                            height: 40px;
                            width: 40px;
                            margin-right: 35px;
                        }
                        .category-name {
                            position: relative;
                            top: 5px;
                        }
                    }
                }
            }
        }
    }
}
