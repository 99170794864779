.brand-lincoln {
    .sub-category-view {
        background-color: $fds-color-lt-gray;

        .sub-category-content {
            max-width: 1440px;
            margin: 60px auto;
            @include tablet {
                margin: 80px auto;
            }
        }
        .sub-category-component-wrapper {
            margin: 0 20px;
            @include desktop {
                margin: 0 70px;
            }
        }
    }
}
