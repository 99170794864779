.brand-lincoln {
    .support-fds-wrapper {
        @include mobile {
            .breadcrumbAccordion {
                display: flex;
                &.expanded {
                    .breadcrumbs {
                        .breadcrumbs-content {
                            overflow: visible;
                            text-overflow: none;
                            white-space: normal;
                        }
                    }
                }
            }
        }
        .breadcrumbs {
            @include mobile {
                width: 85%;
                margin-left: 15px;
                margin-right: 0;
            }
            width: auto;
            padding: 0px;
            font-size: 16px;
            font-weight: 500;
            letter-spacing: 2.1px;
            line-height: 35px;
            background-color: $white-smoke;
            margin-bottom: 13px;
            margin-right: 25px;
            padding-top: 12px;
            display: block;
            margin-left: 103px;
            &.syndicate-header {
                .breadcrumbs-content {
                    margin: 0 auto;
                    @include desktop {
                        margin-left: 0px;
                        padding: 0px;
                    }
                }
            }
            .breadcrumbs-content {
                @include mobile {
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    color: black;
                }
                .breadcrumb-link,
                .back-slash {
                    font-family: $proxima-nova-regular;
                    color: $brand-secondary;
                }
                .current-page {
                    font-family: $proxima-nova-regular;
                    color: $brand-mild-gray1;
                }
            }
        }
        .breadcrumbAccordion {
            @media only screen and (min-width: 768px) and (max-width: 1200px) {
                margin-top: 85px;
            }
            background-color: $white-smoke;
            .fds-chevron-wrap {
                display: none;
                @include mobile {
                    display: block;
                    line-height: 60px;
                }
            }
        }
        color: $fds-color-dk-gray;
        background: $eyebrow-grey;

        h1,
        h2 {
            text-transform: uppercase;
        }

        h1 {
            font-family: 'lincolnmillerbsemibold';
            letter-spacing: 2px;
            line-height: 48px;
            margin: 49px 0 67px 0;
            font-size: 40px;
        }

        h2 {
            font-family: $proxima-nova-regular;
            font-size: 24px;
            letter-spacing: 2px;
            line-height: 32px;
            margin-top: 40px;
        }

        p,
        .fds-content {
            font-family: $proxima-nova-regular;
            color: $brand-secondary;
            font-size: 18px;
            letter-spacing: 0;
            line-height: 24px;
        }
        .fma-login {
            p {
                margin-bottom: 20px;
            }
        }
        .flex-grow-1 {
            flex-grow: 1;
        }

        @media screen and (max-width: $ipad-landscape-width) {
            h1,
            h2 {
                text-align: center;
            }

            h1 {
                margin-bottom: 15px;
                font-size: 34px;
            }

            h2 {
                font-size: 20px;
                margin-top: 20px;
            }

            .width-90-mobile {
                margin: auto;
                width: 90%;
            }
        }
    }
}
