@import '../../../styles/main.scss';
.retrieve-booking-summary {
    width: 90%;
    padding: 5% 0;
    margin: 0 auto;

    @media only screen and (max-width: 948px) {
        width: 100%;
        margin: 0;
    }

    @include mobile {
        width: 100%;
        margin: 0;
        padding: 0;
    }
}
