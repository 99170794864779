.brand-lincoln {
    .scroll-top {
        position: fixed;
        right: 40px;
        opacity: 0;
        pointer-events: none;
        z-index: 2147483649;
        transition: opacity 0.3s;
        &[data-hidden='false'] {
            opacity: 1;
            pointer-events: auto;
            cursor: pointer;
        }
        .fmc-fab {
            --fab-size: 3rem;
            --text-size: 1rem;
            .fmc-fab__text {
                font-size: pToR(16);
            }
            .fds-icon {
                font-size: 2rem;
            }
        }
    }
}
