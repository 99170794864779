.brand-ford {
    .category-view.preimagine {
        background-color: $white;

        .breadcrumbs {
            background-color: $fds-color-white;
            padding-left: 0;

            .breadcrumbs-content {
                margin-left: 16px;
                @include desktop {
                    margin-left: 58px;
                }

                .breadcrumb-link {
                    line-height: 20px;
                    color: $fm-breadcrumb-blue;
                    text-decoration: none;
                    font-size: 14px;
                }

                .back-slash {
                    line-height: 20px;
                    color: $fm-text-grey;
                    margin: 10px;
                }
            }

            .current-page {
                line-height: 20px;
                color: $fm-text-grey;
                font-size: 14px;
            }
        }

        .preimagine-category-masthead {
            .disclaimer__tooltip-symbol {
                color: $white;
                border-bottom: 1px solid $white;
            }
        }

        .preimagine-escape-hatch {
            padding-bottom: 96px;
            margin-bottom: 0;
        }

        .fm-carousel__container {
            &.unimode {
                padding-right: 0px;
                @include desktop {
                    padding-right: 56px;
                }
            }

            &.dark .disclaimer__tooltip-symbol {
                color: $fds-color-white;
                border-color: $fds-color-white;
            }
        }

        .fm-category-fifty-fifty-carousel-header {
            margin-top: 64px;
            padding: 48px 16px 0 16px;
            @include desktop {
                margin-top: 96px;
                padding: 48px 16px 0 56px;
            }

            &:empty {
                padding: 0;
            }

            .fm-category-fifty-fifty-carousel__title {
                font-size: 40px;
                line-height: 48px;
                font-family: $f1-font-regular;
                @include desktop {
                    font-size: 54px;
                    line-height: 64px;
                }
            }

            .fm-category-fifty-fifty-carousel__subtitle {
                padding: 16px 16px 0 0;
                font-size: 16px;
                line-height: 24px;
                font-family: $f1-font-regular;
            }
        }

        .fm-category-articles-trio-header {
            color: $f1-text-blue;
            margin: 64px 16px 0 16px;
            @include desktop {
                margin: 96px 16px 0 56px;
            }

            .fm-category-articles-trio__title {
                font-size: 40px;
                line-height: 48px;
                @include desktop {
                    font-size: 54px;
                    line-height: 64px;
                }
            }

            .fm-category-articles-trio__subtitle {
                margin: 16px 16px 0 0;
                font-size: 16px;
                line-height: 24px;
            }
        }

        .fm-accordion {
            margin: 48px 0;
        }
    }

    .sub-category {
        border: 1px solid $fds-color-dk-gray;
        font-family: $f1-font-light;
        text-align: center;
        line-height: 70px;
    }
}
