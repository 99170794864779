@import '../../../styles/main.scss';
.review-booking-view-container {
    width: 90%;
    margin: 0 auto;
    padding: 5% 0;
    background-color: $fds-color-white;
    @include mobile {
        width: 100%;
        padding: 0;
    }
}
