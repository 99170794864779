.brand-ford {
    .fma-login {
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .login-title {
            align-self: auto;

            &.selector-label {
                margin-top: 0;
                text-transform: uppercase;
                font-family: $f1-font-light;
                color: $fds-color-primary;
                font-size: 1.125rem;
                line-height: 1.875rem;
            }
        }

        p {
            text-align: center;
            font-size: 14px;
        }

        .button-wrapper {
            margin-top: 15px;
            flex-direction: column;
            .primary-button.disabled {
                pointer-events: none;
                &::before {
                    background-color: $fds-color-disabled-dark;
                }
                &:hover {
                    background-color: $fds-color-disabled-dark;
                }
            }

            .add-vehicle-container {
                margin: 32px 0 0;
            }
        }

        .vehicle-selector {
            .vehicle-selector-header {
                width: 90%;
                margin-left: 0;

                .dropdown-container-wrapper {
                    padding-top: 25px;
                    .vehicle-tabs-dropdown {
                        width: 100%;
                    }
                }
            }
        }

        @media screen and (min-width: $ipad-landscape-width) {
            margin: 0;

            .login-title {
                align-self: start;
            }

            p {
                text-align: left;
            }

            .button-wrapper {
                justify-content: flex-start;
                flex-direction: row;
                .primary-button.disabled {
                    pointer-events: none;
                    &::before {
                        background-color: $fds-color-disabled-dark;
                    }
                    &:hover {
                        background-color: $fds-color-disabled-dark;
                    }
                }

                .add-vehicle-container {
                    margin: 0 0 0 30px;
                }
            }

            .vehicle-selector {
                justify-content: flex-start;

                .vehicle-selector-header {
                    margin-left: 0;
                }
            }
        }
        .vehicle-tabs-dropdown {
            .dropdown-current-item {
                color: black;
            }
        }
    }
}
