.brand-ford {
    .roadblock-container {
        width: 100vw;
        height: 80vh;
        display: flex;
        justify-content: center;
        .content {
            margin-top: 45%;
            color: #4d4d4d;
            display: flex;
            flex-direction: column;
            height: fit-content;
            line-height: 58px;

            @include desktop {
                margin-top: 15%;
            }
            @include tablet {
                margin-top: 15%;
            }

            &-title {
                text-transform: uppercase;
                font-size: 44px;
                font-family: $f1-font-light;
                height: 116px;
                margin-bottom: 18px;
                text-align: center;
                @include desktop {
                    height: 56px;
                }
                @include tablet {
                    height: 56px;
                }
            }
            &-subtitle {
                font-size: 16px;
                margin-bottom: 48px;
                line-height: 26px;
                padding: 2%;
                text-align: center;
                @include desktop {
                    padding: 0;
                }
            }
        }
    }
}
