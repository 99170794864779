.brand-lincoln {
    @include mobile {
        .banner {
            .top {
                height: 600px;
            }
            .bottom-container {
                margin-top: -30px;
            }
        }
    }
    .cta {
        width: 100%;
    }
    .error-404-text-container {
        text-align: center;
        padding: 4rem 1.344rem;

        .search-container {
            padding-top: 20px;

            form {
                .autocomplete__input {
                    font-size: pToR(16);
                    font-family: $proxima-nova-regular;
                    color: $brand-primary;
                    letter-spacing: 1px;
                }
            }
        }

        @include tablet {
            padding: 5rem 1.344rem 0;
        }

        .title {
            font-family: $proxima-nova-light;
            text-transform: uppercase;
            font-size: 3rem;

            margin-bottom: 1.125rem;
            letter-spacing: 3px;

            @include desktop {
                margin-bottom: 0.875rem;
                line-height: 3.625rem;
                max-width: inherit;
            }
        }

        .paragraph {
            font-size: 1rem;
            line-height: 1.625rem;
            letter-spacing: 1px;
            max-width: 20.25rem;
            margin: 0 auto;
        }
    }
}
