.brand-ford {
    .osb-main-wrapper-view {
        font-family: $f1-font-regular;
        font-stretch: normal;
        font-style: normal;
        max-width: 1440px;
        margin: 0 auto;
        height: auto;
        background: $fds-color-white;
        .main-wrapper-bottom-space {
            @include mobile {
                height: 70px;
            }
            @media only screen and (max-width: 1180px) and (min-width: 2768px) {
                height: 70px;
            }
        }

        .fds-activity-indicator {
            display: flex;
            flex-direction: column;
        }
    }

    .footer {
        @include mobile {
            margin-bottom: 80px;
        }
    }
    .osbEuHeader {
        position: relative !important;
        .eyebrow {
            position: absolute !important;
        }
    }
}
.osb-loading {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    align-items: center;
    justify-content: center;
    content: '';
    width: 100%;
    background: rgba(246, 246, 246, 0.9);
    height: 100%;
    display: block;
    z-index: 2005;
}
.popup-overlay,
.popup-root,
.osb-overlay-overlay,
.rating-link {
    .osb-loading {
        background: none;
        @include mobile {
            background: rgba(246, 246, 246, 0.9);
        }
    }
}
