@import '../../../styles/main.scss';

.light-personal-details-view-container {
    background-color: $fds-color-white;
    width: 90%;
    margin: 0 auto;
    @include mobile {
        width: 100%;
    }
}
