.brand-lincoln {
    .fma-login {
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .login-title {
            align-self: auto;

            &.selector-label {
                font-family: $proxima-nova-regular;
                color: $brand-secondary;
                margin-top: 0;
                text-transform: uppercase;
                letter-spacing: 3px;
                font-size: 1.125rem;
                line-height: 1.875rem;
                font-weight: 300;
            }
        }

        p {
            text-align: center;
        }

        .button-wrapper {
            flex-direction: row;
            @media screen and (max-width: $small-desktop-width) {
                flex-direction: column;
            }
            .primary-button.disabled {
                pointer-events: none;
                &::before {
                    background-color: $fds-color-disabled-dark;
                }
                &:hover {
                    background-color: $fds-color-disabled-dark;
                }
            }

            @media screen and (max-width: $small-desktop-width) {
                .add-vehicle-container {
                    margin: 32px 0 0;
                }
            }
        }

        .vehicle-selector {
            .vehicle-selector-header {
                width: 90%;
                margin-left: 0;

                .dropdown-container-wrapper {
                    .vehicle-tabs-dropdown {
                        width: 100%;
                    }
                }
            }
        }

        @media screen and (min-width: $ipad-landscape-width) {
            margin: 0;

            .login-title {
                align-self: start;
            }

            p {
                text-align: left;
            }

            .button-wrapper {
                justify-content: flex-start;
                flex-direction: row;
                .primary-button.disabled {
                    pointer-events: none;
                    &::before {
                        background-color: $fds-color-disabled-dark;
                    }
                    &:hover {
                        background-color: $fds-color-disabled-dark;
                    }
                }

                .add-vehicle-container {
                    margin: 0 0 0 30px;
                }
            }

            .vehicle-selector {
                justify-content: flex-start;

                .vehicle-selector-header {
                    margin-left: 0;
                }
            }
        }
        .vehicle-tabs-dropdown {
            .dropdown-current-item {
                color: $fds-color-black;
            }
        }
    }
}
