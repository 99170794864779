.brand-ford {
    .support-fds-wrapper {
        .breadcrumbs {
            @include mobile {
                width: 85%;
            }
            width: auto;
            padding: 0px;
            font-size: 16px;
            line-height: 35px;
            background-color: $white-smoke;
            padding-bottom: 13px;
            padding-right: 25px;
            padding-top: 12px;
            display: block;
            padding-left: 103px;
            &.syndicate-header {
                .breadcrumbs-content {
                    margin: 0 auto;
                    @include desktop {
                        margin-left: 0px;
                        padding: 0px;
                    }
                }
            }
            .breadcrumbs-content {
                @include mobile {
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    color: black;
                }
                .breadcrumb-link,
                .back-slash {
                    font-family: $f1-font-regular;
                    color: $dark-blue;
                }
                .current-page {
                    font-family: $f1-font-regular;
                    color: $fm-text-grey;
                }
            }
        }
        .breadcrumbAccordion {
            .fds-chevron-wrap {
                display: none;
                @include mobile {
                    display: block;
                    line-height: 60px;
                }
            }
        }
        @include mobile {
            .breadcrumbAccordion {
                background-color: whitesmoke;
                display: flex;
                .breadcrumbs {
                    padding-left: 15px;
                }
                &.expanded {
                    .breadcrumbs {
                        .breadcrumbs-content {
                            overflow: visible;
                            text-overflow: none;
                            white-space: normal;
                        }
                    }
                }
            }
        }
        font-family: $f1-font-regular;
        color: $fds-color-dk-gray;

        h1,
        h2 {
            font-family: $f1-font-light;
            text-transform: uppercase;
        }

        h1 {
            line-height: 44px;
            margin-bottom: 80px;
            font-size: 48px;
        }

        h2 {
            line-height: 30px;
            margin-top: 40px;
        }

        p,
        .fds-content {
            font-family: $f1-font-regular;
            color: $fds-color-dk-gray;
            line-height: 26px;
        }

        .flex-grow-1 {
            flex-grow: 1;
        }

        @media screen and (max-width: $ipad-landscape-width) {
            h1,
            h2 {
                text-align: center;
            }

            h1 {
                margin-bottom: 15px;
                font-size: 34px;
            }

            h2 {
                font-size: 20px;
                margin-top: 20px;
            }

            .width-90-mobile {
                margin: auto;
                width: 90%;
            }
        }
    }
}
