.brand-lincoln {
    .support-hero-tiles-container {
        text-align: center;
        width: 65%;
        @include mobile {
            width: 97%;
        }
        @media screen and (min-width: 768px) and (max-width: 1180px) {
            width: 100%;
        }

        padding: 0 1rem;
        position: relative;
        top: -75px;
        margin: auto;

        .tile-grid {
            display: grid;
            justify-content: center;
            grid-gap: 25px;
            margin-top: 52px;
            grid-template-columns: 1fr;
            .hero-tile {
                width: 100%;
                height: auto;
                padding-top: 0;
                .content {
                    display: flex;
                    margin-bottom: 12px;

                    .fds-chevron-wrap {
                        padding-left: 12px;
                    }
                }
                .tile-icon {
                    height: 1.5rem;
                    width: 1.5rem;
                }
                &:hover {
                    transform: scale(1.04);
                }
            }
            @include tablet {
                grid-template-columns: 1fr 1fr 1fr;
            }
        }
    }
}
