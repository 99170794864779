.brand-lincoln {
    .lincoln-na-header {
        .container {
            margin: 0;
        }
    }
    .osb-main-wrapper-view {
        font-family: $f1-font-regular;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        background-color: $fds-color-white;
        .main-wrapper-bottom-space {
            @include mobile {
                height: 70px;
            }
        }
        .fds-activity-indicator {
            display: flex;
            flex-direction: column;
        }
    }

    .footer {
        @include mobile {
            margin-bottom: 80px;
        }
    }
}
