@import '../../../styles/main';
.retrieve-booking-step-view-container {
    background-color: #f4f4f4;
    width: 90%;
    margin: 0 auto;
    padding: 0;
    @include mobile {
        background-color: #ffffff;
        width: 100%;
        padding: 0;
    }
}
