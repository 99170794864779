.brand-ford {
    .ute-page {
        padding: 10px 80px;
        font-family: $f1-font-regular;
        background-color: $eyebrow-grey;
        color: $fds-color-dk-gray;
        @include mobile {
            margin: 0;
            padding: 10px 20px;
        }

        .ute-accordion {
            @include desktop {
                height: auto;
                font-family: $f1-font-regular;
            }
            .accordion-title__header {
                font-family: $f1-font-regular;
            }
            .border-bottom {
                @include mobile {
                    width: 390px;
                }
            }

            .fds-arrow {
                color: #00095b;
            }
        }
        .ute-modal-alert {
            .modalTitle {
                font-size: 26px;
                line-height: 40px;
                text-align: center;
                margin-bottom: 21px;
            }
            .modal-body {
                padding: 0px 20px;
            }
            .modal-content {
                @include mobile {
                    width: 85%;
                }
            }
            .modal-footer {
                flex-direction: revert;
                .modal-footer-buttons {
                    .text-button {
                        @include mobile {
                            margin-left: 0px;
                            margin-top: 21px;
                        }
                    }
                    flex-direction: column;
                    @include tablet {
                        flex-direction: row;
                    }
                }
            }
            .modalDesc {
                font-size: 16px;
                line-height: 24px;
                text-align: center;
            }
        }
    }
}
