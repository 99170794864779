.brand-ford {
    .vehicle-not-found-cta-text {
        text-align: center;
        padding: 4rem 1.344rem;

        @include tablet {
            padding: 5rem 0;
        }

        .title {
            font-family: $f1-font-light;
            text-transform: uppercase;
            font-size: 2rem;
            margin-bottom: 1.125rem;

            @include tablet {
                font-size: 3rem;
            }

            @include desktop {
                margin-bottom: 0.875rem;
                line-height: 3.625rem;
                max-width: inherit;
            }
        }

        .paragraph {
            font-size: 1rem;
            line-height: 1.625rem;
            max-width: 34rem;
            margin: 0 auto;
        }

        .asterisk {
            @include tablet {
                display: none;
            }
        }
    }
    .bottom {
        .bottom-container {
            .input-container {
                .ymm-vin-header {
                    @include desktop {
                        min-height: 60px;
                        align-items: unset;
                    }
                }

                .dropdown-container-wrapper {
                    @include desktop {
                        padding-top: 15px;
                    }
                }
            }
        }
    }
}
