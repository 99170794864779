@import '../../../styles/main.scss';
.services-step-view-container {
    background-color: $fds-color-white;
    width: 90%;
    margin: 0 auto;
    @include mobile {
        width: 100%;
    }
    .osb-loading {
        .fds-activity-indicator.full-height {
            display: flex;
            flex-direction: column;
        }
    }
}
