@import '../../../styles/main.scss';
.dealer-step-view-container {
    height: 100%;
    background-color: $fds-color-white;
    @include mobile {
        width: 100%;
    }
}
.new-dealer-step-view-container {
    height: 100%;
    background-color: $fds-color-white;
    margin: 0 auto;
    width: 100%;
    padding: 30px;
    @include mobile {
        width: 100%;
        margin: 0;
        padding: 0;
    }
}
.mobile-retrieve-container {
    background-color: $fds-color-white;
    border-top: 0.2px solid $border-grey;
    bottom: 0;
    box-shadow: 10px 10px 10px 10px rgba(0, 0, 0, 0.1),
        10px 20px 20px 10px rgba(0, 0, 0, 0.1);
    position: fixed;
    width: 100%;
    z-index: 2004;
}
.mobile-retrieve-section {
    .accordion-title {
        border: none !important;
        height: 62px;
        .accordion-title__header {
            color: $fds-color-primary;
            font-family: $f1-font-regular;
            font-size: 16px;
            line-height: 24px;
        }

        .fds-chevron.down {
            color: $fds-color-primary;
        }
    }

    .retrieve-flow-link {
        display: flex;
        align-items: center;
        div {
            width: 90%;
        }
        .fds-chevron-wrap {
            width: 10%;
            margin-top: 10px;
        }
    }
}
.retrieve-flow-cta {
    display: flex;
    background-color: $fds-color-white;
    min-height: 71px;
    align-items: center;
    padding: 0 50px;
    @include mobile {
        margin: 0 0 30px 0;
        height: auto;
        padding: 0 20px;
        display: block;
        align-items: start;
    }
    p {
        width: 75%;
        color: $fds-color-dk-gray;
        font-family: $f1-font-regular;
        font-size: 14px;
        line-height: 22px;
        padding: 10px 0;
        @include mobile {
            width: 100%;
            text-align: start;
        }
    }
    .retrieve-btn {
        width: 25%;
        @include mobile {
            width: 100%;
        }
        button {
            height: 40px;
            font-size: 14px;
            float: right;
            @include mobile {
                float: none;
                align-items: center;
                margin: 0 auto;
            }
            &::after {
                background-color: $fm-skyview;
            }
        }
    }
}
