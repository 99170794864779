@import '../../../styles/main';
.retrieve-access-code-view-container {
    width: 90%;
    margin: 0 auto;
    background-color: #fff;
    @include mobile {
        background-color: #ffffff;
        width: 100%;
        padding: 0;
        margin: 0 auto;
    }
}
