.brand-ford {
    .shared-view.video-details {
        margin-top: 0px;
    }
    .htv-video-page {
        background-color: $fds-color-lt-gray;
        .breadcrumbs {
            width: 90%;
            .breadcrumbs-content {
                display: block;
                @include mobile {
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    color: $fds-color-md-gray;
                }
            }
        }
        .breadcrumbAccordion {
            .fds-chevron-wrap {
                display: none;
                @include mobile {
                    display: block;
                    line-height: 70px;
                }
            }
            @include mobile {
                display: flex;
                &.expanded {
                    .breadcrumbs {
                        .breadcrumbs-content {
                            overflow: visible;
                            text-overflow: none;
                            white-space: normal;
                        }
                    }
                }
            }
        }
        .htv-content-wrapper {
            width: 100%;

            .video-page-title {
                font-size: 1.2rem;
                text-transform: uppercase;
                color: $fds-color-dk-gray;
                font-family: $f1-font-light;
                margin: 2.5rem auto 0 auto;
                width: 90%;

                @include tablet {
                    font-size: 1.4rem;
                }

                @include xl-desktop {
                    width: 80%;
                    margin: 55px 10% 0px 10%;
                }
            }
            .how-to-video-popular-videos {
                margin-bottom: 64px;
            }
            .empty-video {
                text-align: center;
                font-family: $f1-font-regular;
                font-size: 1rem;
                padding: 150px 0;
            }
            .no-video {
                padding-bottom: 125px;
            }
        }
    }
}
