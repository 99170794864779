@import '../../../styles/main.scss';
.osb-v3-contact-details-step-view-container {
    height: 100%;
    margin: 0 auto;
    padding: 30px;
    width: 100%;
    @include mobile {
        padding: 0px;
    }
}
