@import '../../styles/main.scss';

.vehicle-data-display {
    display: flex;
    flex-direction: column;
    justify-content: center;

    .error-view {
        margin: 0 auto 2.25rem;
        max-width: 80rem;
        padding: 0 1rem;
        width: calc(100% - 2rem);

        @include tablet {
            margin: 0 auto 5.563rem;
        }

        .error-message {
            font-family: $f1-font-regular;
            font-size: 0.75rem;
            margin: 0;
            color: $error-text;
            line-height: 1.3125rem;

            @include tablet {
                margin: 1.625rem 0 2.5rem;
                padding: 0 1.25rem;
            }
        }

        .vehicle-selector-mobile-hr {
            display: none;
        }
    }
}
