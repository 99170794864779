@import '../../styles/lincoln/main.scss';

.brand-lincoln {
    .tirefinder {
        .feature-trios {
            @media print {
                display: none;
            }
            margin: auto;
            .feature-trio {
                margin-bottom: 0;
                .section-heading-link {
                    display: none;
                }
                .feature-trio-cards {
                    .card-wrap {
                        margin-bottom: 1rem;
                        display: flex;
                        justify-content: center;
                        .card {
                            background-color: $white;
                            width: 397px;
                            .card-body {
                                .button-position {
                                    .primary-button {
                                        color: $brand-secondary;
                                        background-color: $brand-white;
                                        padding: 0;
                                        box-shadow: none;
                                        font-family: $proxima-nova-bold;
                                        &:hover {
                                            border-color: $brand-border;
                                            cursor: pointer;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        .dealer-caption {
            font-size: 11px;
            letter-spacing: 0;
            line-height: 13px;
            font-family: $proxima-nova-semi-bold;
            color: $brand-secondary;
            max-width: 1239px;
            margin: 0 auto;
            padding: 3rem 1rem;
        }
    }
}
