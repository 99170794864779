.brand-ford {
    .sub-category-view {
        background-color: $fds-color-lt-gray;

        .sub-category-content {
            max-width: 1440px;
            margin: 60px auto;
            @include tablet {
                margin: 80px auto;
            }
        }
        .sub-category-component-wrapper {
            margin: 0 20px;
            @include desktop {
                margin: 0 70px;
            }
        }
    }
    .sub-category-view.preimagine {
        background-color: $fds-color-white;
        .breadcrumbs {
            background-color: $fds-color-white;
            padding-left: 0;
            .breadcrumbs-content {
                margin-left: 16px;
                @include desktop {
                    margin-left: 58px;
                }
                .breadcrumb-link {
                    line-height: 20px;
                    color: $fm-breadcrumb-blue;
                    text-decoration: none;
                    font-size: 14px;
                }
                .back-slash {
                    line-height: 20px;
                    color: $fm-text-grey;
                    margin: 10px;
                }
            }
            .current-page {
                line-height: 20px;
                color: $fm-text-grey;
                font-size: 14px;
            }
        }
        .sub-category-content {
            margin: 48px auto 0 auto;
            @include desktop {
                margin: 76px auto 0 auto;
            }

            .sub-category-component-wrapper {
                .simple-cards-wrapper {
                    .simple-cards {
                        @include tablet {
                            grid-gap: 20px;
                            padding-bottom: 0;
                        }
                        @include desktop {
                            grid-template-columns: repeat(4, 1fr);
                        }
                    }

                    .simple-cards,
                    .simple-cards-mobile {
                        @include tablet {
                            padding: 0;
                        }
                        .fm-carousel_container {
                            padding: unset;
                        }
                        .simple-cards-mobile-card {
                            text-align: unset;
                            background: #fafafa;
                            border-radius: 8px;
                            padding: 8px 0 0 8px;
                            @include tablet {
                                width: 100%;
                                padding: 24px;
                            }
                        }

                        .simple-cards-mobile-icon {
                            margin: unset;
                        }

                        .simple-cards-mobile-headline {
                            color: $dyf-color-darkgrey;
                            font-size: pToR(32);
                            line-height: pToR(40);
                            padding: 24px 0;
                            margin-bottom: unset;
                            text-transform: unset;
                            max-width: 80%;
                        }

                        .simple-cards-mobile-description {
                            color: $dyf-color-darkgrey;
                            margin: unset;
                            font-size: pToR(16);
                            line-height: pToR(24);
                        }

                        .carousel-wrap {
                            margin: 0;

                            .carousel-wrap__items {
                                gap: 100px;
                            }
                        }
                    }
                }

                .sub-category-articles-container {
                    .item-2 {
                        margin-top: 18px;
                        @include tablet {
                            margin-top: 25px;
                        }
                    }

                    .popular-links-section {
                        .related-categories,
                        .popular-in-rewards {
                            &__header {
                                font-size: 24px;
                                line-height: 32px;
                                color: $dyf-color-darkgrey;
                                margin-bottom: 16px;
                            }

                            &__item {
                                margin-top: unset;
                                margin-bottom: 19px;

                                a {
                                    color: $fm-breadcrumb-blue;
                                    font-size: 16px;
                                    line-height: 24px;
                                }
                            }
                        }

                        .popular-in-rewards {
                            margin-top: 48px;
                        }

                        hr {
                            display: none;
                        }
                    }
                }
            }
        }
        .preimagine-escape-hatch {
            padding-bottom: 96px;
            margin-bottom: 0px;
        }
    }
}
